import { RefObject, useEffect, useRef } from 'react';

export default function useMounted(): RefObject<boolean> {
  const mountedRef = useRef<boolean>(false);
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, [mountedRef]);

  return mountedRef;
}
